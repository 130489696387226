@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

html {
  font-size: 20px;
}
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;

  &:focus {
      outline: none;
  }
}
h1{
  font-size: 1.6rem;
}
h2 {
  font-size: 1.3rem;
  font-weight: 300;
}
h3 {
  font-size: 1.1rem;
  font-weight: 400;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}